export const _DEFAULT_ZONES = {
  bpm: {
    z1: 50,
    z2: 60,
    z3: 70,
    z4: 80,
    z5: 90,
  },
  vo2: {
    v1: 35,
    v2: 60,
    v3: 73,
    v4: 86,
    v5: 100,
  },
  ve: {
    ve1: 50,
    ve2: 60,
    ve3: 70,
    ve4: 80,
    ve5: 90,
  },
};
