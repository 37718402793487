import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DeviceBatteryStatus } from '../models/device-battery-status.enum';
import { DeviceStatus } from '../models/device-status.enum';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { WSMessagePlayer } from '../models/ws-message-player.model';
import { WSMessage } from '../models/ws-message.model';

export const _INTERVAL = 3000;

@Injectable({
  providedIn: 'root',
})
export class FakeSignalRService {
  private apiUrl = `${this.env.server.url}/players`;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient
  ) { }

  /**
   * DEV GENERAZIONE MESSAGGI RANDOM
   *
   */
  intervalId: any;

  stopSendingRandomMessages(): void {
    // Interrompe l'invio dei messaggi casuali cancellando l'intervallo
    clearInterval(this.intervalId);
  }

  generateRandomMessage(): WSMessage {
    // Genera un messaggio casuale con dati casuali
    const randomMessage: WSMessage = {
      Date: new Date(),
      Players: [],
    };

    for (let i = 1; i <= 5; i++) {
      // Puoi regolare il numero di giocatori
      const randomPlayer: WSMessagePlayer = {
        pId: `${i}`,
        pName: `Player ${i}`,
        pNum: `${Math.floor(Math.random() * 100)}`,
        dId: `Device_${i}`,
        dS: this.getRandomDeviceStatus(),
        dB: this.getRandomDeviceBatteryStatus(),
        Bpm: Math.floor(Math.random() * 100),
        Vo2: Math.floor(Math.random() * 100),
        Br: Math.floor(Math.random() * 100),
        Ve: Math.floor(Math.random() * 100),
        HRt: Math.floor(Math.random() * 10),
        VO2t: Math.floor(Math.random() * 10),
        maxHRt: Math.floor(Math.random() * 10),
        maxVO2t: Math.floor(Math.random() * 10),
      };

      randomMessage.Players.push(randomPlayer);
    }

    return randomMessage;
  }

  getRandomDeviceStatus(): DeviceStatus {
    const statusValues = Object.values(DeviceStatus);
    const randomIndex = Math.floor(Math.random() * statusValues.length);
    return statusValues[randomIndex];
  }

  getRandomDeviceBatteryStatus(): DeviceBatteryStatus {
    const batteryStatusValues = Object.values(DeviceBatteryStatus);
    const randomIndex = Math.floor(Math.random() * batteryStatusValues.length);
    return batteryStatusValues[randomIndex];
  }
}
