export enum DeviceBatteryStatus {
  // Charged = 'Charged',
  // Charging = 'Charging',
  High = 'High',
  Medium = 'Medium',
  Low = 'Low',
  // Drained = 'Drained',
}

export const DeviceBatteryStatusLabel = new Map<string, string>([
  // [DeviceBatteryStatus.Charged, 'device.batteryStatus.fullyCharged'],
  // [DeviceBatteryStatus.Charging, 'device.batteryStatus.charging'],
  [DeviceBatteryStatus.High, 'device.batteryStatus.high'],
  [DeviceBatteryStatus.Medium, 'device.batteryStatus.medium'],
  [DeviceBatteryStatus.Low, 'device.batteryStatus.low'],
  // [DeviceBatteryStatus.Drained, 'device.batteryStatus.drained'],
]);
