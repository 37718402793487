import { NgIf } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { SessionCategory, SessionCategoryLabel } from '@oxypeak/commons';

@Component({
  standalone: true,
  selector: 'oxy-session-category',
  templateUrl: './session-category.component.html',
  styleUrls: ['./session-category.component.scss'],
  imports: [NgIf, TranslateModule, MatIconModule],
})
export class SessionCategoryComponent {
  @Input() category!: SessionCategory | undefined;
  @Input() button = false;

  @HostBinding('class.button') get isButton() {
    return this.button;
  }

  SessionCategoryLabel = SessionCategoryLabel;
  SessionCategory = SessionCategory;
}
