<mat-selection-list [multiple]="false" (selectionChange)="onSelectRole($event)">
  <mat-list-option
    [value]="null"
    [ngClass]="{ active: roleSelected === null }"
    checkboxPosition="before"
    >{{ "analyze.allRoles" | translate }}</mat-list-option
  >
  <mat-list-option
    *ngFor="let role of PlayerRole | keyvalue"
    [value]="role"
    [ngClass]="{ active: roleSelected === role.key }"
    checkboxPosition="before"
  >
    {{ PlayerRoleLabel.get(role.value) + "" | translate }}
  </mat-list-option>
</mat-selection-list>
