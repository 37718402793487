import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { TranslateModule } from '@ngx-translate/core';
import { PlayerRole, PlayerRoleLabel } from '@oxypeak/commons';

@Component({
  standalone: true,
  selector: 'role-selector',
  templateUrl: './role-selector.component.html',
  styleUrls: ['./role-selector.component.scss'],
  imports: [MatListModule, CommonModule, TranslateModule],
})
export class RoleSelectorComponent {
  PlayerRoleLabel = PlayerRoleLabel;
  PlayerRole = PlayerRole;

  @Input() roleSelected: string | null = null;
  @Output() onRoleSelected = new EventEmitter<string | null>();

  constructor() {}

  onSelectRole(role: MatSelectionListChange) {
    const roleKey = role.source.selectedOptions.selected[0].value?.key || null;
    this.roleSelected = roleKey;

    if (!roleKey) this.roleSelected = null;
    else this.roleSelected = roleKey;

    this.onRoleSelected.emit(this.roleSelected);
  }
}
