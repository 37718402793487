export interface Permissions {
  dashboard: Permission;
  team: Permission;
  realTime: Permission;
  analyze: Permission;
  test: Permission;
  admin: Permission;
}

export interface Permission {
  read: boolean;
  write: boolean;
}

export enum PermissionKeys {
  dashboard = 'dashboard',
  team = 'team',
  realTime = 'realTime',
  analyze = 'analyze',
  test = 'test',
  admin = 'admin',
}
