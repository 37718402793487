import { Component, Input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  selector: 'abs-spinner',
  template: `<mat-spinner
    [diameter]="diameter"
    strokeWidth="5"
  ></mat-spinner> `,
  styles: [
    `
      :host {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ddd;
        opacity: 0.5;
        z-index:9999;
      }
    `,
  ],
  imports: [MatProgressSpinnerModule],
})
export class AbsSpinnerComponent {
  @Input() diameter: number = 40;
}
