<ng-container *ngIf="connectionState$ | async as state">
  <div
    class="frcc gap-0-5 signalr-state state-{{ state }}"
    (click)="onClick(state)"
    [matTooltip]="'signalr.status.tooltip.' + state | translate"
  >
    <mat-icon>signal_cellular_alt</mat-icon>
    <ng-container *ngIf="description">
      {{ "signalr.status.description." + state | translate }}
    </ng-container>
  </div>
</ng-container>
