import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

let dialogServiceInstance: MatDialog;
export const dialogService = () => dialogServiceInstance;

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private dialog: MatDialog) {
    dialogServiceInstance = dialog;
  }
}
