<button mat-icon-button color="primary" (click)="refresh()">
  <mat-icon>refresh</mat-icon>
</button>

<mat-form-field class="search-field" appearance="outline" *ngIf="textFieldEnabled">
  <mat-icon matPrefix>search</mat-icon>
  <mat-label>{{ "pageFilter.search" | translate }}</mat-label>
  <input
    matInput
    #idFilterInput="matInput"
    type="text"
    [(ngModel)]="searchText"
    (ngModelChange)="searchTextUpdate.next($event)"
    id="search"
    autocomplete="off"
  />
  <mat-icon
    matSuffix
    style="cursor: pointer"
    (click)="searchText = ''; searchTextUpdate.next(''); idFilterInput.focus()"
    *ngIf="searchText != ''"
    >close</mat-icon
  >
</mat-form-field>

<ng-content></ng-content>
