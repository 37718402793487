import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { AuthService, TeamService } from '@oxypeak/commons';

import { map } from 'rxjs';

@Injectable()
export class UserTeamGuard {
  constructor(
    private router: Router,
    private teamService: TeamService,
    private authService: AuthService
  ) {}

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url === '/team/create') {
      return true;
    }

    if (this.authService.isSuperAdmin()) {
      return true;
    }

    return this.teamService.whenUserTeam().pipe(
      map((team) => {
        if (Object.keys(team).length === 0) {
          return this.router.navigate(['/', 'team', 'create']); // Redirect to Team Edit Page
        } else {
          return true; // Allow navigation
        }
      })
    );
  }
}
