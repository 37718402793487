<mat-icon class="status_{{ status }}">
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="DeviceBatteryStatus.Low"
      >battery_1_bar</ng-container
    >
    <ng-container *ngSwitchCase="DeviceBatteryStatus.Medium"
      >battery_4_bar</ng-container
    >
    <ng-container *ngSwitchCase="DeviceBatteryStatus.High"
      >battery_full</ng-container
    >
  </ng-container>
</mat-icon>
