import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Pagination } from '@ermitsrl/commons/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ChartType } from '../models/chart-type.enum';
import { DashboardChartData } from '../models/dashboard-chart-data.model';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { PlayerTrainingStats } from '../models/player.model';
import { Session } from '../models/session.model';
import { TrainingChartData } from '../models/training-chart-data.model';
import { TrainingStatus } from '../models/training-status.enum';
import { Training } from '../models/training.model';
import { ZoneChartData } from '../models/zone-chart-data.model';
import { LoggerService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class TrainingService {
  private apiUrl = `${this.env.server.url}/training`;

  private currentTraining$ = new BehaviorSubject<Training | null>(null);
  private currentTraining!: Training | null;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient,
    private logger: LoggerService
  ) {
    this.resetCurrentTraining();
  }

  whenCurrentTraining(): Observable<Training | null> {
    return this.currentTraining$.asObservable();
  }

  setCurrentTraining(currentTraining: Training) {
    this.currentTraining = currentTraining;
    this.currentTraining$.next(this.currentTraining);
  }

  setCurrentTrainingName(teamId: string, trainingName: string) {
    this.currentTraining = {
      teamId,
      name: trainingName,
    } as Training;
    this.currentTraining$.next(this.currentTraining);
  }

  resetCurrentTraining() {
    this.currentTraining = null;
    this.currentTraining$.next(this.currentTraining);
  }

  getTrainings(httpParams: HttpParams): Observable<Pagination<Training>> {
    return this.http.get<Pagination<Training>>(this.apiUrl, {
      params: httpParams,
    });
  }

  getTrainingsDashboard(
    httpParams: HttpParams
  ): Observable<Pagination<Training>> {
    return this.http.get<Pagination<Training>>(this.apiUrl, {
      params: httpParams,
    });
  }

  getTraining(trainingId: string): Observable<Training> {
    let url = `${this.apiUrl}/${trainingId}`;
    return this.http.get<Training>(url);
  }

  getTrainingStats(
    trainingId: string
  ): Observable<Training> {
    let url = `${this.apiUrl}/${trainingId}/stats`;
    return this.http.get<Training>(url);
  }

  /**
   * Check if there is a training in progress
   *
   * @param teamId
   * @returns Training
   */
  checkTraining(teamId: string): Observable<Training> {
    return this.http.get<Training>(`${this.apiUrl}/check?teamId=${teamId}`);
  }

  getTrainingChartData(trainingId: string): Observable<TrainingChartData[]> {
    return this.http.get<TrainingChartData[]>(
      'assets/analyze-training-chart-data.json'
    );
    // return this.http.get<TrainingChartData[]>(`${this.apiUrl}/${trainingId}`);
  }

  getTrainingPlayersStats(
    trainingId: string | null,
    sessionId: string | null,
    role: string | null
  ): Observable<PlayerTrainingStats[]> {
    // return this.http
    //   .get<PlayerTrainingStats[]>('assets/training-players-data.json')
    // .pipe(delay(1600));

    let url = `${this.apiUrl}/${trainingId}/players/stats`;

    // Aggiungi i parametri solo se sono diversi da null
    if (sessionId !== null) {
      url += `?sessionId=${sessionId}`;
    }
    if (role !== null) {
      // Usa '&' se c'è già un parametro nella URL
      url += sessionId !== null ? `&role=${role}` : `?role=${role}`;
    }

    return this.http.get<PlayerTrainingStats[]>(url);
  }

  getDashboardChartData(
    from: string,
    to: string,
    role: string | null
  ): Observable<DashboardChartData[]> {
    return this.http
      .get<DashboardChartData[]>('assets/dashboard-chart-data.json')
      .pipe(delay(500));
    // return this.http.get<PlayerTrainingStats[]>(`${this.apiUrl}/${trainingId}`);
  }

  getZoneChartData(
    trainingId: string,
    typeZone: ChartType,
    sessionId: string | null,
    role: string | null
  ): Observable<ZoneChartData[]> {

    // TO SIM
    // if(typeZone === ChartType.BPM) {
    //   return of(simBPM_2)
    // }

    // if(typeZone === ChartType.VO2) {
    //   return of(simVO2_2)
    // }
    let url = `${this.apiUrl}/${trainingId}/zones/stats?typeZone=${typeZone}`;

    if (sessionId !== null) {
      url += `&sessionId=${sessionId}`;
    }
    if (role) {
      url += `&role=${role}`;
    }

    return this.http.get<ZoneChartData[]>(url);
  }

  updateTraining(training: Partial<Training>): Observable<Training> {
    return this.http.put<Training>(`${this.apiUrl}/${training.id}`, training);
  }

  deleteTraining(training: Partial<Training>): Observable<Training> {
    return this.http.delete<Training>(`${this.apiUrl}/${training.id}`);
  }

  /**
   * Start Real Time Training
   * @returns Training
   */
  startTraining(training: Training): Observable<Training> {
    const url = `${this.apiUrl}/start`;

    training.status = TrainingStatus.Running;
    training.duration = 0;
    training.startedAt = new Date();

    return this.http.post<Training>(url, training);
  }

  /**
   * Pause Real Time Training
   * @returns Training
   */
  pauseTraining(training: Training): Observable<Training> {
    return this.http.post<Training>(`${this.apiUrl}/${training.id}/pause`, {});
  }

  /**
   * Resume Real Time Training
   * @returns Training
   */
  resumeTraining(training: Training): Observable<Training> {
    return this.http.post<Training>(`${this.apiUrl}/${training.id}/resume`, {});
  }

  /**
   * Stop Real Time Training
   * @returns Training
   */
  stopTraining(training: Training): Observable<Training> {
    return this.http.post<Training>(`${this.apiUrl}/${training.id}/stop`, {});
  }

  /**
   * Start a new Real Time Session
   */
  startSession(training: Training, session: Session): Observable<Session> {
    return this.http.post<Session>(
      `${this.apiUrl}/${training.id}/session/start`,
      session
    );
  }

  /**
   * Stop a Real Time Session
   */
  stopSession(training: Training): Observable<Training> {
    return this.http.post<Training>(
      `${this.apiUrl}/${training.id}/session/${training?.currentSession?.id}/stop`,
      {}
    );
  }
}

const simBPM_1 = [
  {
      "threshold": "Z1",
      "duration": 7,
      "formattedDuration": "00:00:17",
      "percentage": 0,
      "avgValue": 131
  },
  {
      "threshold": "Z2",
      "duration": 10,
      "formattedDuration": "00:04:22",
      "percentage": 0,
      "avgValue": 146
  },
  {
      "threshold": "Z3",
      "duration": 7,
      "formattedDuration": "00:10:03",
      "percentage": 0,
      "avgValue": 164
  },
  {
      "threshold": "Z4",
      "duration": 48,
      "formattedDuration": "00:09:53",
      "percentage": 0,
      "avgValue": 177
  },
  {
      "threshold": "Z5",
      "duration": 28,
      "formattedDuration": "00:01:59",
      "percentage": 0,
      "avgValue": 184
  }
]

const simVO2_1 = [
  {
      "threshold": "V1",
      "duration": 5,
      "formattedDuration": "",
      "percentage": 0,
      "avgValue": 0
  },
  {
      "threshold": "V2",
      "duration": 6,
      "formattedDuration": "00:00:19",
      "percentage": 0,
      "avgValue": 33
  },
  {
      "threshold": "V3",
      "duration": 10,
      "formattedDuration": "00:01:52",
      "percentage": 0,
      "avgValue": 37
  },
  {
      "threshold": "V4",
      "duration": 9,
      "formattedDuration": "00:03:37",
      "percentage": 0,
      "avgValue": 43
  },
  {
      "threshold": "V5",
      "duration": 70,
      "formattedDuration": "00:20:46",
      "percentage": 0,
      "avgValue": 55
  }
]


const simBPM_2 = [
  {
      "threshold": "Z1",
      "duration": 8,
      "formattedDuration": "00:00:17",
      "percentage": 0,
      "avgValue": 131
  },
  {
      "threshold": "Z2",
      "duration": 10,
      "formattedDuration": "00:04:22",
      "percentage": 0,
      "avgValue": 146
  },
  {
      "threshold": "Z3",
      "duration": 7,
      "formattedDuration": "00:10:03",
      "percentage": 0,
      "avgValue": 164
  },
  {
      "threshold": "Z4",
      "duration": 53,
      "formattedDuration": "00:09:53",
      "percentage": 0,
      "avgValue": 177
  },
  {
      "threshold": "Z5",
      "duration": 22,
      "formattedDuration": "00:01:59",
      "percentage": 0,
      "avgValue": 184
  }
]

const simVO2_2 = [
  {
      "threshold": "V1",
      "duration": 3,
      "formattedDuration": "",
      "percentage": 0,
      "avgValue": 0
  },
  {
      "threshold": "V2",
      "duration": 6,
      "formattedDuration": "00:00:19",
      "percentage": 0,
      "avgValue": 33
  },
  {
      "threshold": "V3",
      "duration": 10,
      "formattedDuration": "00:01:52",
      "percentage": 0,
      "avgValue": 37
  },
  {
      "threshold": "V4",
      "duration": 80,
      "formattedDuration": "00:03:37",
      "percentage": 0,
      "avgValue": 43
  },
  {
      "threshold": "V5",
      "duration": 1,
      "formattedDuration": "00:20:46",
      "percentage": 0,
      "avgValue": 55
  }
]
