import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { debounceTime, Subject } from 'rxjs';

@Component({
  standalone: true,
  selector: 'page-filters',
  templateUrl: './page-filters.component.html',
  styleUrls: ['./page-filters.component.scss'],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    NgIf,
    TranslateModule,
  ],
})
export class PageFiltersComponent {
  @Output() onFilterChange = new EventEmitter<any>();
  @Output() onOpenFilterDialog = new EventEmitter<void>();

  @Input() textFieldEnabled = true;

  searchText = '';
  searchTextUpdate = new Subject<string>();
  searchSub = this.searchTextUpdate
    .pipe(debounceTime(300))
    .subscribe((value) => {
      this.onFilterChange.emit({ text: value });
    });

  constructor() {}

  filtersChanged(filter: any) {
    this.onFilterChange.emit(filter);
  }

  onSearchChange($event: any) {}

  refresh() {
    this.onFilterChange.emit();
  }

  openFiltersDialog() {
    this.onOpenFilterDialog.emit();
  }
}
