import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { TrainingReport } from '../models/training-report.interface';
import { LoggerService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private apiUrl = `${this.env.server.url}/report`;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient,
    private logger: LoggerService
  ) {}

  getTrainingsReport(from: Date, to: Date): Observable<TrainingReport> {
    const url = `${
      this.apiUrl
    }?from=${from.toISOString()}&to=${to.toISOString()}`;

    this.logger.debug('getTrainingsReport', url);

    return this.http.get<TrainingReport>(url);
  }

  getTrainingsReportExcel(from: Date, to: Date): Observable<Blob> {
    const url = `${
      this.apiUrl
    }/xls?from=${from?.toISOString()}&to=${to?.toISOString()}`;

    this.logger.debug('getTrainingsReportExcel', url);

    return this.http.get(url, {
      responseType: 'blob',
      observe: 'body',
    });
  }

  getSessionReportExcel(
    trainingId: string,
    sessionId: string
  ): Observable<Blob> {
    const url = `${this.apiUrl}/session/xls?trainingId=${trainingId}&sessionId=${sessionId}`;

    this.logger.debug('getSessionReportExcel', url);

    return this.http.get(url, {
      responseType: 'blob',
      observe: 'body',
    });
  }

  getTrainingReportExcel(sessionId: string, from: Date, to: Date) {
    const url = `${
      this.apiUrl
    }/session/xls?sessionId=${sessionId}&from=${from.toISOString()}&to=${to.toISOString()}`;

    this.logger.debug('getSessionReportExcel', url);

    return this.http.get(url, {
      responseType: 'blob',
      observe: 'body',
    });
  }

  // getTrainingsDashboard(
  //   httpParams: HttpParams
  // ): Observable<Pagination<Training>> {
  //   return this.http.get<Pagination<Training>>(this.apiUrl, {
  //     params: httpParams,
  //   });
  // }

  // getTraining(trainingId: string): Observable<Training> {
  //   return this.http.get<Training>(`${this.apiUrl}/${trainingId}`);
  // }
}
