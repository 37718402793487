import { LogLevel, OxypeakEnvironment } from '@oxypeak/commons';

const tenantName = 'oxypeakb2c';
const b2cPolicyNames = {
  signUpSignIn: 'B2C_1_oxypeak_signin',
};
const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: `https://${tenantName}.b2clogin.com/${tenantName}.onmicrosoft.com/${b2cPolicyNames.signUpSignIn}`,
    },
  },
  authorityDomain: 'oxypeakb2c.b2clogin.com',
};

export const environment: OxypeakEnvironment = {
  logger: {
    level: LogLevel.Debug,
    consoleLog: true,
    webApiLog: false,
  },
  server: {
    url: 'https://be-dev.oxypeak.it/v1',
    ws: {
      url: 'https://socket-dev.oxypeak.it/teamHub',
    },
  },
  apiConfig: {
    scopes: [
      'https://oxypeakb2c.onmicrosoft.com/9a24abf9-4262-4596-84bf-b169def65afa/read',
      'offline_access',
      'openid',
    ],
    uri: 'https://be-dev.oxypeak.it/v1/*',
  },
  msalConfig: {
    auth: {
      clientId: '9a24abf9-4262-4596-84bf-b169def65afa',
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [b2cPolicies.authorityDomain],
      redirectUri: 'https://web-dev.oxypeak.it',
      postLogoutRedirectUri: 'https://web-dev.oxypeak.it/logged-out',
    },
  },
};
