export const chartColors = [
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
  '#F44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#CDDC39',
  '#FFC107',
  '#FF9800',
  '#FF5722',
  '#795548',
  '#9E9E9E',
  '#607D8B',
];

export const plotBandsColor = '#ffebd2';
export const plotBandsColorDuringSelection = '#cccccc85'; // opacity 85%

export const bpmColor = '#F44336';
export const vo2Color = '#2851dd';
export const veColor = '#007E34';
export const brColor = '#f79d0c';

export const veColors = [
  '#004d20',
  '#005524',
  '#005d28',
  '#00652c',
  '#006d30',
  '#007534',
  '#007E34',
  '#00853c',
  '#008d40',
  '#009544',
  '#009d48',
  '#00a54c',
  '#00ad50',
  '#00b554',
  '#00bd58',
  '#00c55c',
  '#00cd60',
  '#00d564',
  '#00dd68',
  '#00e56c',
];

export const bpmColors = [
  '#922820',
  '#9c2b23',
  '#a62e25',
  '#b03027',
  '#b93329',
  '#c3362b',
  '#cd382d',
  '#d73b30',
  '#e03e32',
  '#ea4034',
  '#f44336',
  '#fe4638',
  '#ff483a',
  '#ff4b3c',
  '#ff4e3f',
  '#ff5041',
  '#ff5343',
  '#ff5645',
  '#ff5847',
  '#ff5b49',
];

export const vo2Colors = [
  '#1a31a3',
  '#1e37b3',
  '#223dc3',
  '#2643d3',
  '#2a49e3',
  '#2e4ff3',
  '#2851dd',
  '#3659ff',
  '#3a5dff',
  '#3e61ff',
  '#4265ff',
  '#4669ff',
  '#4a6dff',
  '#4e71ff',
  '#5275ff',
  '#5679ff',
  '#5a7dff',
  '#5e81ff',
  '#6285ff',
  '#6689ff',
];

export const brColors = [
  '#c47a09',
  '#ce8109',
  '#d8880a',
  '#e28f0b',
  '#ec960c',
  '#f69d0d',
  '#f79d0c',
  '#faa10d',
  '#fea50e',
  '#ffaa0f',
  '#ffae10',
  '#ffb211',
  '#ffb612',
  '#ffba13',
  '#ffbe14',
  '#ffc215',
  '#ffc616',
  '#ffca17',
  '#ffce18',
  '#ffd219',
];
