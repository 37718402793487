import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: '[form-errors]',
  templateUrl: './form-errors.component.html',
  imports: [CommonModule, TranslateModule, MatFormFieldModule],
})
export class FormErrorsComponent {
  @Input() form!: FormGroup;
  @Input() fieldName!: string;
  @Input() minLength = 3;
  @Input() maxLength = 50;
  @Input() minValue = 1;
  @Input() maxValue = 100;
}
