import * as am5 from '@amcharts/amcharts5';

import { ChartType } from '../models/chart-type.enum';

export const colors = {
  [ChartType.BPM]: [
    am5.color('#ABCBCD'),
    am5.color('#84B1B4'),
    am5.color('#79A9AD'),
    am5.color('#327D82'),
    am5.color('#D32F2F'),
  ],

  [ChartType.VO2]: [
    am5.color('#D5D5D5'),
    am5.color('#C1C1C1'),
    am5.color('#ACACAC'),
    am5.color('#979797'),
    am5.color('#D32F2F'),
  ],

  [ChartType.VE]: [
    am5.color('#979797'),
    am5.color('#979797'),
    am5.color('#979797'),
    am5.color('#979797'),
    am5.color('#D32F2F'),
  ],
};

/**
 * Zone colors (Garmin style)
 */
export const zoneColors = [
  am5.color('#78c7f7'),
  am5.color('#39a9ec'),
  am5.color('#50af12'),
  am5.color('#f79d0c'),
  am5.color('#f4253c'),
];

export const BPMcolor = am5.color('#D32F2F');
export const VO2color = am5.color('#303F9F');
export const VEcolor = am5.color('#007E34');
export const BRcolor = am5.color('#f79d0c');

export const BPMcolorNew = am5.color('#327D82');
export const VO2colorNew = am5.color('#979797');
export const VEcolorNew = am5.color('#007E34');
export const BRcolorNew = am5.color('#f79d0c');

export const zone4color = '#f79d0c';
export const zone5color = '#f4253c';
