import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { BackButtonDirective } from '../../directives/back-button.directive';

@Component({
  standalone: true,
  selector: 'back-button',
  template: ` <button
    mat-mini-fab
    color="primary"
    backButton
    [defaultBackRoute]="defaultBackRoute"
  >
    <mat-icon>close</mat-icon>
  </button>`,
  styles: [
    `
      :host {
        height: 40px;
        width: 40px;
        color: #fff;
        font-size: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        ::ng-deep .mdc-fab--mini {
          box-shadow: none;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, BackButtonDirective, MatIconModule],
})
export class BackButtonComponent {
  @Input() defaultBackRoute: string[] = ['/'];
}
