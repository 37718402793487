export enum DeviceStatus {
  Inactive = 'Inactive',
  Connected = 'Connected',
  Searching = 'Searching',
  Connecting = 'Connecting',
  Disconnecting = 'Disconnecting',
  Disconnected = 'Disconnected',
}

export const DeviceStatusLabel = new Map<DeviceStatus, string>([
  [DeviceStatus.Inactive, 'device.status.inactive'],
  [DeviceStatus.Connected, 'device.status.connected'],
  [DeviceStatus.Searching, 'device.status.searching'],
  [DeviceStatus.Connecting, 'device.status.connecting'],
  [DeviceStatus.Disconnecting, 'device.status.disconnecting'],
  [DeviceStatus.Disconnected, 'device.status.disconnected'],
]);
