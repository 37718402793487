import { AuthenticationResult, EventMessage, EventType } from '@azure/msal-browser';
import { filter, take } from 'rxjs';

import { ChangeDetectorRef, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, SignalrService, TeamService } from '@oxypeak/commons';
import { DialogService } from './core/services/dialog.service';
import { LanguageService } from './core/services/language.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  template: `<router-outlet></router-outlet>`,
})
export class AppComponent {

  constructor(
    private signalRService: SignalrService,
    translate: TranslateService,
    private dialogService: DialogService,
    private languageService: LanguageService,
    private authService: AuthService,
    private teamService: TeamService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private cdRef: ChangeDetectorRef
  ) {
    translate.setDefaultLang('it');
    translate.use('it');
  }

  ngOnInit() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
      )
      .subscribe((result: EventMessage) => {
        // console.log('LOGIN_SUCCESS', result);
        const payload = result.payload as AuthenticationResult;
        this.msalService.instance.setActiveAccount(payload.account);

      });

    this.authService.isLoggedIn$.pipe(take(1)).subscribe((__) => this.init());

    this.cdRef.detectChanges();
  }

  init() {
    if (!this.authService.isSuperAdmin()) {
      this.teamService.fetchUserTeam();
      const userTeamId = this.authService.getUserTeamId();
      if (userTeamId) this.signalRService.startConnection(userTeamId);
    }
  }
}
