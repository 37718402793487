import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';

import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { Session } from '../models/session.model';
import { TrainingChartData } from '../models/training-chart-data.model';
import { Training } from '../models/training.model';
import { LoggerService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private apiUrl = `${this.env.server.url}/training`;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient,
    private logger: LoggerService
  ) { }

  fetchTraining(trainingId: string) {
    const url = `${this.apiUrl}/${trainingId}`; // training with stats
    return this.http.get<Training>(url);
  }

  fetchTrainingStats(trainingId: string) {
    const url = `${this.apiUrl}/${trainingId}/stats`; // training with stats
    return this.http.get<Training>(url);
  }

  fetchTrainingAndSession(trainingId: string, sessionId: string) {
    const session$ = this.getSession(trainingId, sessionId);
    // const training$ = this.fetchTrainingStats(trainingId);
    const training$ = this.fetchTraining(trainingId);

    return forkJoin([session$, training$]);
  }

  getPlayerChartData(trainingId: string, playerId: string) {
    // TO SIM PRESENTAZIONE SIVIGLIA
    // return this.http.get<TrainingChartData[]>(
    //   'assets/complete.json'
    // );

    let url = `${this.apiUrl}/${trainingId}/player?playerId=${playerId}`;
    // url = 'assets/analyze-training-chart-data.json';
    return this.http.get<TrainingChartData[]>(url);
  }

  /**
   * CRUD Session
   */
  createSession(session: Partial<Session>): Observable<Session> {
    let url = `${this.apiUrl}/${session.trainingId}/session`;
    return this.http.post<Session>(url, session);
  }

  updateSession(session: Session): Observable<Session> {
    let url = `${this.apiUrl}/${session.trainingId}/session`;
    return this.http.put<Session>(url, session);
  }

  getSession(trainingId: string, sessionId: string): Observable<Session> {
    let url = `${this.apiUrl}/${trainingId}/session/${sessionId}`;
    return this.http.get<Session>(url);
  }

  deleteSession(trainingId: string, sessionId: string): Observable<Session> {
    let url = `${this.apiUrl}/${trainingId}/session/${sessionId}`;
    return this.http.delete<Session>(url);
  }
}
