import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'empty',
  template: ``,
})
export class EmptyComponent {
  constructor() {
    console.error('EmptyComponent');
  }
}
