import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  DateAdapter,
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatDialogModule,
  MAT_DIALOG_DEFAULT_OPTIONS
} from '@angular/material/dialog';
import {
  MatFormFieldModule,
  MAT_FORM_FIELD_DEFAULT_OPTIONS
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ErmitTableComponent } from '@ermitsrl/commons/table';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { AbsSpinnerComponent } from './components/abs-spinner/abs-spinner.component';
import { BackButtonComponent } from './components/back-button/back-button.component';
import { BatteryStatusComponent } from './components/battery-status/battery-status.component';
import { DeviceStatusComponent } from './components/device-status/device-status.component';
import { EditableInputComponent } from './components/editable-input/editable-input.component';
import { FormErrorsComponent } from './components/form-errors/form-errors.component';

import { PageContainerComponent } from './components/page-container/page-container.component';
import { PageContentWithFiltersComponent } from './components/page-content-with-filters/page-content-with-filters.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { PageFiltersComponent } from './components/page-filters/page-filters.component';
import { PageFooterComponent } from './components/page-footer/page-footer.component';
import { PageTitleComponent } from './components/page-title/page-title.component';
import { RoleSelectorComponent } from './components/role-selector/role-selector.component';
import { SessionCategoryComponent } from './components/session-category/session-category.component';
import { SingalRStateComponent } from './components/signalr-state/signalr-state.component';
import { SpinnerComponent } from './components/spinner/spinner.component';

import { BackButtonDirective } from './directives/back-button.directive';
import { EditModeDirective } from './directives/edit-mode.directive';
import { ViewModeDirective } from './directives/view-mode.directive';
import { WritePermissionCheckPipe } from './pipes/write-permission-check.pipe';

const _PIPES = [WritePermissionCheckPipe];

const _COMPONENTS = [
  ErmitTableComponent,
  PageContainerComponent,
  PageContentComponent,
  PageContentWithFiltersComponent,
  PageFooterComponent,
  PageTitleComponent,
  FormErrorsComponent,
  DeviceStatusComponent,
  BatteryStatusComponent,
  BackButtonComponent,
  SingalRStateComponent,
  EditableInputComponent,
  SpinnerComponent,
  PageFiltersComponent,
  RoleSelectorComponent,
  SessionCategoryComponent,
  AbsSpinnerComponent,
];

const _DIRECTIVES = [
  BackButtonDirective,
  ViewModeDirective,
  EditModeDirective,
  NgxMaskDirective,
];

const _MODULES = [CommonModule, RouterModule, TranslateModule];

const _MATERIAL_MODULES = [
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  FormsModule,
  ReactiveFormsModule,
  MatIconModule,
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
  MatToolbarModule,
  MatCheckboxModule,
  MatDialogModule,
  MatRippleModule,
  MatMenuModule,
  MatTableModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatPaginatorModule,
  MatSortModule,
  MatRadioModule,
  MatButtonToggleModule,
  MatStepperModule,
  MatListModule,
];

const IT_DATE_FORMAT = {
  parse: {
    dateInput: 'DD/MM/YYYY', // this is how your date will be parsed from Input
  },
  display: {
    dateInput: 'DD/MM/YYYY', // this is how your date will get displayed on the Input
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  imports: [_PIPES, _MODULES, _MATERIAL_MODULES, _COMPONENTS, _DIRECTIVES],
  exports: [_PIPES, _MODULES, _MATERIAL_MODULES, _COMPONENTS, _DIRECTIVES],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' },
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        minWidth: '30vw',
        maxWidth: '95vw',
        maxHeight: '95vh',
        autoFocus: true,
        panelClass: 'oxy-dialog',
        hasBackdrop: true,
      },
    },
    provideNgxMask(),
    { provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: IT_DATE_FORMAT },
  ],
})
export class SharedModule {}
