// Create new angular Pipe

import { Pipe, PipeTransform } from '@angular/core';
import { PermissionKeys } from '@oxypeak/commons';
import { Observable, of, switchMap } from 'rxjs';
import { AuthService } from '../../../../../oxypeak/commons/src/lib/services/auth.service';

@Pipe({
  name: 'writePermissionCheck',
  pure: true,
  standalone: true,
})
export class WritePermissionCheckPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(permissionKey: PermissionKeys | null): Observable<boolean> {
    return this.authService.permissions$.pipe(
      switchMap((permissions) => {
        if (!permissionKey) return of(false);
        return permissions[permissionKey]?.write ? of(true) : of(false);
      })
    );
  }
}
