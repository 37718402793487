import { ModuleWithProviders, NgModule } from '@angular/core';
import { CustomIconsService } from './services/custom-icons-service';

@NgModule({
  declarations: [],
  exports: [],
})
export class OxypeakCommonsModule {
  constructor(customIconsService: CustomIconsService) {
    customIconsService.init();
  }

  static forRoot(environment: any): ModuleWithProviders<OxypeakCommonsModule> {
    return {
      ngModule: OxypeakCommonsModule,
      providers: [
        {
          provide: 'env',
          useValue: environment,
        },
      ],
    };
  }
}
