import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Pagination } from '@ermitsrl/commons/table';
import { Observable } from 'rxjs';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { Player } from '../models/player.model';

@Injectable({
  providedIn: 'root',
})
export class PlayerService {
  private apiUrl = `${this.env.server.url}/player`;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient
  ) { }

  getPlayers(httpParams: HttpParams): Observable<Pagination<Player>> {
    return this.http.get<Pagination<Player>>(this.apiUrl, {
      params: httpParams,
    });
  }

  getPlayer(id: string): Observable<Player> {
    return this.http.get<Player>(`${this.apiUrl}/${id}`);
  }

  createPlayer(player: Partial<Player>): Observable<Player> {
    return this.http.post<Player>(this.apiUrl, player);
  }

  updatePlayer(player: Partial<Player>): Observable<Player> {
    return this.http.put<Player>(`${this.apiUrl}/${player.id}`, player);
  }

  deletePlayer(id: string): Observable<Player> {
    return this.http.delete<Player>(`${this.apiUrl}/${id}`);
  }

  pairDevice(playerId: string, deviceId: string): Observable<Player> {
    return this.http.put<Player>(`${this.apiUrl}/${playerId}/pair`, {
      playerId,
      deviceId,
    });
  }

  unpairDevice(playerId: string): Observable<Player> {
    return this.http.put<Player>(`${this.apiUrl}/${playerId}/pair`, {
      playerId,
    });
  }
}
