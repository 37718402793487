import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';

import { HubConnectionState } from '@microsoft/signalr';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService, SignalrService } from '@oxypeak/commons';

@Component({
  standalone: true,
  selector: 'signalr-state',
  templateUrl: './signalr-state.component.html',
  styleUrls: ['./signalr-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  imports: [MatTooltipModule, MatIconModule, TranslateModule, CommonModule],
})
export class SingalRStateComponent {
  @Input() description = true;
  HubConnectionState = HubConnectionState;
  connectionState$ = this.signalRService.getConnectionState()

  constructor(
    private signalRService: SignalrService,
    private authService: AuthService
  ) {}

  onClick(state: HubConnectionState) {
    if (state === HubConnectionState.Disconnected) {
      const userTeamId = this.authService.getUserTeamId();
      this.signalRService.startConnection(userTeamId);
    }
  }
}
