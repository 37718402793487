import { HttpBackend, provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import {
  importProvidersFrom,
  isDevMode,
  LOCALE_ID
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  PreloadAllModules,
  provideRouter,
  withPreloading,
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { OxypeakCommonsModule } from '@oxypeak/commons';
import { HighchartsChartModule } from 'highcharts-angular';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { AppComponent } from './app/app.component';
import { AuthModule } from './app/auth/auth.module';
import { RedirectByRoleGuard } from './app/core/guards/redirect-by-role.guard';
import { UserTeamGuard } from './app/core/guards/user-team.guard';
import { HttpLoaderFactory } from './app/core/http-loader.factory';
import { APP_ROUTES } from './app/routes';
import { SharedModule } from './app/shared/shared.module';
import { environment } from './environments/environment';

import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';

registerLocaleData(localeIt);

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      APP_ROUTES,
      withPreloading(PreloadAllModules)
      // withDebugTracing()
    ),
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    importProvidersFrom(
      BrowserAnimationsModule,
      SharedModule,
      AuthModule,
      OxypeakCommonsModule.forRoot(environment),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpBackend],
        },
        defaultLanguage: 'it',
      }),
      HighchartsChartModule
    ),
    provideEnvironmentNgxMask(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    UserTeamGuard,
    RedirectByRoleGuard,
    { provide: LOCALE_ID, useValue: 'it-IT' },
  ],
}).catch((err) => console.error(err));
