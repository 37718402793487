import { Component } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  standalone: true,
  selector: 'oxy-spinner',
  template: `<mat-spinner diameter="50"></mat-spinner>`,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        opacity: 0.5;
        transition: opacity 0.2s ease-in-out;
      }
    `,
  ],
  imports: [MatProgressSpinnerModule],
})
export class SpinnerComponent {}
