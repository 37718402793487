export enum TrainingStatus {
  Running = 'Running',
  Paused = 'Paused',
  Stopped = 'Stopped',
}

export const TrainingStatusLabel = new Map<TrainingStatus, string>([
  [TrainingStatus.Running, 'training.status.running'],
  [TrainingStatus.Paused, 'training.status.paused'],
  [TrainingStatus.Stopped, 'training.status.stopped'],
]);
