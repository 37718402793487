export enum SessionCategory {
  FullTraining = 'FULL_TRAINING',
  Exercise = 'EXERCISE',
  ReturnToPlay = 'RETURN_TO_PLAY',
  OfficialMatch = 'OFFICIAL_MATCH',
  FriendlyMatch = 'FRIENDLY_MATCH',
}

export const SessionCategoryLabel = new Map<SessionCategory, string>([
  [SessionCategory.FullTraining, 'session.category.fullTraining'],
  [SessionCategory.Exercise, 'session.category.exercise'],
  [SessionCategory.ReturnToPlay, 'session.category.returnToPlay'],
  [SessionCategory.OfficialMatch, 'session.category.officialMatch'],
  [SessionCategory.FriendlyMatch, 'session.category.friendlyMatch'],
]);
