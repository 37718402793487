import { NgTemplateOutlet } from '@angular/common';
import { Component, ContentChild, EventEmitter, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { EditModeDirective } from '../../directives/edit-mode.directive';
import { ViewModeDirective } from '../../directives/view-mode.directive';

@Component({
  standalone: true,
  selector: 'oxy-editable-input',
  templateUrl: './editable-input.component.html',
  styleUrls: ['./editable-input.component.scss'],
  imports: [NgTemplateOutlet],
})
export class EditableInputComponent {
  @Output() updated = new EventEmitter();
  @ContentChild(ViewModeDirective) viewModeTpl!: ViewModeDirective;
  @ContentChild(EditModeDirective) editModeTpl!: EditModeDirective;

  mode: 'view' | 'edit' = 'view';
  editMode = new Subject();
  editMode$ = this.editMode.asObservable();

  constructor() {}

  get currentView() {
    return this.mode === 'view' ? this.viewModeTpl.tpl : this.editModeTpl.tpl;
  }

  ngOnInit() {}

  setEditMode() {
    this.mode = 'edit';
    this.editMode.next(true);
  }

  setViewMode() {
    this.mode = 'view';
    this.editMode.next(false);
  }
}
