import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input
} from '@angular/core';

@Component({
  standalone: true,
  selector: 'page-container',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: var(--oxy-background-light);
        &.padding {
          padding: 1rem 1.5rem;
        }
        gap: 1rem;
        &.with-footer {
          margin-bottom: 80px;
        }
        overflow: hidden;
        &.overflow {
          overflow: auto;
        }
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageContainerComponent {
  @Input() @HostBinding('class.with-footer') footer: boolean = false;
  @Input() @HostBinding('class.overflow') overflow: boolean = true;
  @Input() @HostBinding('class.padding') padding: boolean = true;
}
