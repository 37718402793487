import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { RedirectByRoleGuard } from './core/guards/redirect-by-role.guard';
import { UserTeamGuard } from './core/guards/user-team.guard';
import { teamResolver } from './core/resolvers/team.resolver';
import { EmptyComponent } from './shared/components/empty/empty.component';

export const APP_ROUTES: Route[] = [
  {
    path: '',
    component: EmptyComponent,
    canActivate: [RedirectByRoleGuard],
  },
  {
    path: 'require-login',
    loadComponent: () =>
      import('./pages/require-login/require-login.component').then(
        (m) => m.RequireLoginComponent
      ),
  },
  {
    path: 'logging-in',
    loadComponent: () =>
      import('./pages/logging-in/logging-in.component').then(
        (m) => m.LoggingInComponent
      ),
  },
  {
    path: 'logged-out',
    loadComponent: () =>
      import('./pages/logged-out/logged-out.component').then(
        (m) => m.LoggedOutComponent
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import('./shared/components/app-container/app-container.component').then(
        (m) => m.AppContainerComponent
      ),
    canActivate: [MsalGuard],
    canActivateChild: [UserTeamGuard],
    children: [
      {
        path: 'admin',
        loadChildren: () =>
          import('./pages/admin/routes').then((mod) => mod.ADMIN_ROUTES),
      },
      {
        path: '404',
        loadComponent: () =>
          import('./pages/not-found/not-found.component').then(
            (m) => m.NotFoundComponent
          ),
      },
    ],
  },
  {
    path: ':teamId',
    loadComponent: () =>
      import('./shared/components/app-container/app-container.component').then(
        (m) => m.AppContainerComponent
      ),
    canActivate: [MsalGuard],
    resolve: { team: teamResolver },
    runGuardsAndResolvers: 'always',
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: () =>
      //     import('./pages/dashboard/routes').then(
      //       (mod) => mod.DASHBOARD_ROUTES
      //     ),
      // },
      {
        path: 'test',
        loadChildren: () =>
          import('./pages/test/routes').then((mod) => mod.TEST_ROUTES),
      },
      {
        path: 'real-time',
        loadChildren: () =>
          import('./pages/real-time/routes').then(
            (mod) => mod.REAL_TIME_ROUTES
          ),
      },
      {
        path: 'analyze',
        loadChildren: () =>
          import('./pages/analyze/routes').then((mod) => mod.ANALYZE_ROUTES),
      },
      {
        path: 'team',
        loadChildren: () =>
          import('./pages/team/routes').then((mod) => mod.TEAM_ROUTES),
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./pages/users/routes').then((mod) => mod.USER_ROUTES),
      },
      {
        path: 'team',
        loadChildren: () =>
          import('./pages/team/routes').then((mod) => mod.TEAM_ROUTES),
      },
      {
        path: 'help',
        loadChildren: () =>
          import('./pages/help/routes').then((m) => m.HELP_ROUTES),
      },
    ],
  },
  {
    path: '**',
    redirectTo: '404',
  },
];
