<div class="title">
  <div class="frcs gap-1 start">
    <ng-content select="[start]"></ng-content>
    <ng-content select="[start-title]"></ng-content>
  </div>
  <div class="frcc content">
    <ng-content></ng-content>
  </div>
  <div class="frce end">
    <ng-content select="[end]"></ng-content>
  </div>
</div>
<div class="row">
  <ng-content select="[row]"></ng-content>
</div>
