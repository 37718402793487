import { Inject, Injectable } from '@angular/core';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionState,
  LogLevel
} from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr/dist/esm/HubConnectionBuilder';
import {
  BehaviorSubject,
  combineLatest,
  map,
  Observable, Subject,
  take
} from 'rxjs';

import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { TrainingStatus } from '../models/training-status.enum';
import { WSHubClientMessage } from '../models/ws-hub-client-message.model';
import { WSMessage } from '../models/ws-message.model';
import { FakeSignalRService } from './fake-signalr.service';
import { LoggerService } from './log.service';

const _MAX_TRIES = 5;
const _RECONNECT_TIME = 500;

@Injectable({
  providedIn: 'root',
})
export class SignalrService {
  private wsUrl = `${this.env.server.ws.url}`;

  private hubConnection!: HubConnection;
  private hubConnectionState$: BehaviorSubject<HubConnectionState> =
    new BehaviorSubject<HubConnectionState>(HubConnectionState.Disconnected);
  private connectionState: HubConnectionState = HubConnectionState.Disconnected;

  public get whenHubConnectionState(): Observable<HubConnectionState> {
    return this.hubConnectionState$.asObservable();
  }

  private $WSMessage: Subject<WSMessage> = new Subject<WSMessage>();
  private $WSHubClientMessage: BehaviorSubject<WSHubClientMessage> =
    new BehaviorSubject<WSHubClientMessage>({ Connected: false });

  public get whenWSMessage(): Observable<WSMessage> {
    return this.$WSMessage.asObservable();
  }

  public get whenWSHubClientMessage(): Observable<WSHubClientMessage> {
    return this.$WSHubClientMessage.asObservable();
  }

  private userTeamId!: string;
  private tries = 0;
  private maxTries = _MAX_TRIES;
  private reconnectTime = _RECONNECT_TIME;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private logger: LoggerService,
    private fakeSignalRService: FakeSignalRService
  ) { }

  public startConnection(userTeamId: string) {
    if (this.hubConnection) return;

    this.userTeamId = userTeamId;
    const hubUrl = `${this.wsUrl}?teamId=${this.userTeamId}`;

    this.hubConnection = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        skipNegotiation: false,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect([0, 1000, 2000, 5000, 10000])
      .configureLogging(LogLevel.Debug)
      .build();

    this.tryConnection();

    this.hubConnection.onreconnecting(() => {
      this.logger.debug(
        'SignalrService - SignalR connection lost, trying to reconnect...'
      );
      this.hubConnectionState$.next(HubConnectionState.Reconnecting);
    });
    this.hubConnection.onreconnected(() => this.onConnected());
  }

  public disconnect() {
    this.hubConnection?.stop();
  }

  public getConnectionState(): Observable<HubConnectionState> {
    return combineLatest([
      this.whenWSHubClientMessage,
      this.whenHubConnectionState,
      this.$WSHubClientMessage,
    ]).pipe(
      map(([hubClientMessage, connectionState, WSHubClientMessage]) => {
        if (hubClientMessage.Connected && WSHubClientMessage) {
          this.connectionState = HubConnectionState.Connected;
          return HubConnectionState.Connected;
        }

        return hubClientMessage.Connected &&
          connectionState === HubConnectionState.Connected
          ? HubConnectionState.Connected
          : connectionState === HubConnectionState.Connected
            ? HubConnectionState.Reconnecting
            : (connectionState as HubConnectionState);
      })
    );
  }

  public listenWSMessage() {
    this.logger.debug('SignalrService - WebClient_TrainingMessage Listener');
    this.hubConnection.on('WebClient_TrainingMessage', (data: string) => {
      let dataParsed: WSMessage;
      try {
        dataParsed = <WSMessage>JSON.parse(data);
      } catch (err) {
        this.logger.error('Error parsing WS message: ', data);
        dataParsed = <WSMessage>{};
      }
      this.logger.debug('WSMessage received: ', dataParsed);
      // this.logger.debug('WSMessage received - Players:', dataParsed.Players);
      this.$WSMessage.next(dataParsed);
    });
  }

  public listenWSHubClientMessage() {
    this.logger.debug('SignalrService - WSHubClientMessage Listener');
    this.hubConnection.on('WebClient_HubClientMessage', (data: string) => {
      let wsHubClientMessage: WSHubClientMessage = <WSHubClientMessage>{
        Connected: false,
      };
      try {
        wsHubClientMessage = <WSHubClientMessage>JSON.parse(data);
      } catch (err) {
        this.logger.error('Error parsing WSHubClientMessage: ', data);
        wsHubClientMessage = <WSHubClientMessage>{ Connected: false };
      }
      this.logger.debug('WSHubClientMessage received: ', wsHubClientMessage);
      this.$WSHubClientMessage.next(wsHubClientMessage);
    });
  }

  private trainingStatusMessage$: Subject<TrainingStatus> =
    new Subject<TrainingStatus>();

  public whenTrainingStatusMessage(): Observable<TrainingStatus> {
    return this.trainingStatusMessage$.asObservable();
  }

  public listenWSTrainingStatusMessage() {
    this.logger.debug('SignalrService - WebClient_TrainingStatus Listener');
    this.hubConnection.on('WebClient_TrainingStatus', (data: string) => {
      try {
        const trainingStatus = <TrainingStatus>JSON.parse(data);

        this.logger.debug(
          'SignalrService - WebClient_TrainingStatus Listener',
          trainingStatus
        );
        this.trainingStatusMessage$.next(<TrainingStatus>trainingStatus);
      } catch (err) {
        this.logger.error(
          'SignalrService - WebClient_TrainingStatus Listener parsing error',
          data,
          err
        );
      }
    });
  }

  public askForMessage(teamId: string) {
    this.logger.debug('SignalrService - AskForMessage');
    this.getConnectionState()
      .pipe(take(1))
      .subscribe((connectionState) => {
        if (connectionState === HubConnectionState.Connected) {
          (<HubConnection>this.hubConnection).invoke(
            'WebClient_AskForMessage',
            teamId
          );
        } else {
          this.logger.error(
            'WebClient_AskForMessage not sent because connectionState is not Connected'
          );
        }
      });
  }

  public startingTrainingMessage(teamId: string) {
    return new Promise((resolve, reject) => {
      (<HubConnection>this.hubConnection)
        .invoke('WebClient_StartingTraining', teamId)
        .then(
          () => {
            this.logger.debug(`SignalrService - StartingTraining`);
            return resolve(true);
          },
          (err: any) => {
            this.logger.error('SignalrService - StartingTraining error', err);
            return reject(err);
          }
        );
    });
  }

  // private connectionState(): HubConnectionState {
  //   return this.hubConnection?.state;
  // }

  private tryConnection() {
    this.hubConnection
      .start()
      .then(() => {
        this.logger.debug('SignalrService - SignalR connection established');
        this.hubConnectionState$.next(HubConnectionState.Connected);
        this.reconnectTime = _RECONNECT_TIME;
        this.tries = 0;
        this.onConnected();
      })
      .catch((err: any) => {
        this.logger.error(
          'SignalrService - SignalR connection error occured',
          err
        );
        this.hubConnectionState$.next(HubConnectionState.Disconnected);
        this.tries += 1;
        this.reconnectTime *= 2;
        setTimeout(() => this.tryConnection(), this.reconnectTime);
      });
  }

  private onConnected() {
    // Abilito la ricezione dei messaggi
    this.listenWSMessage();

    // Abilito la ricezione dei messaggi relativi al hub client
    this.listenWSHubClientMessage();
    this.listenWSTrainingStatusMessage();
  }
}
