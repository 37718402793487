import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Pagination } from '@ermitsrl/commons/table';
import { Observable } from 'rxjs';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private apiUrl = `${this.env.server.url}/user`;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient
  ) {}

  getUsers(httpParams: HttpParams): Observable<Pagination<User>> {
    return this.http.get<Pagination<User>>(this.apiUrl, {
      params: httpParams,
    });
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(`${this.apiUrl}/${id}`);
  }

  createUser(player: Partial<User>): Observable<User> {
    return this.http.post<User>(this.apiUrl, player);
  }

  updateUser(user: Partial<User>): Observable<User> {
    return this.http.put<User>(`${this.apiUrl}/${user.id}`, user);
  }

  deleteUser(id: string): Observable<User> {
    return this.http.delete<User>(`${this.apiUrl}/${id}`);
  }
}
