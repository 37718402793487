import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot
} from '@angular/router';
import { LoggerService, Team, TeamService } from '@oxypeak/commons';
import { tap } from 'rxjs/operators';

export const teamResolver: ResolveFn<Team | null> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const teamService = inject(TeamService);
  const logger = inject(LoggerService);
  const teamId = route.params['teamId'];

  return teamService.resolveTeam(teamId).pipe(
    tap((team) => {
      logger.log(`TEAM RESOLVED id: ${teamId}`, team);
      if (team) teamService.setResolvedTeam(team);
    })
  );
};
