import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, NgZone, PLATFORM_ID } from '@angular/core';
import { TrainingChartData } from '../models/training-chart-data.model';

@Injectable({
  providedIn: 'root',
})
export class ChartUtilsService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone
  ) {
    // this.date.setHours(0, 0, 0, 0);
  }

  // date = new Date('2023-09-07T14:00:00Z').getTime();
  date = 1694095203000;
  bpm = 100;
  vo2 = 100;
  ve = 100;
  generateData() {
    // Ensure small random fluctuations keep values within bounds
    this.bpm += Math.max(
      -5,
      Math.min(5, Math.round((Math.random() * 10 - 5) * 10) / 10)
    );
    this.vo2 += Math.max(
      -2.5,
      Math.min(2.5, Math.round((Math.random() * 5 - 2.5) * 10) / 10)
    );
    this.ve += Math.max(
      -5,
      Math.min(5, Math.round((Math.random() * 10 - 5) * 10) / 10)
    );

    // Ensure values are between specified ranges
    this.bpm = Math.max(60, Math.min(220, this.bpm));
    this.vo2 = Math.max(35, Math.min(62, this.vo2));
    this.ve = Math.max(60, Math.min(220, this.ve));

    // Introduce a slight upward trend over time
    this.bpm += 0.05;
    this.vo2 += 0.02;
    this.ve += 0.05;

    const currentDate = new Date(this.date);
    am5.time.add(currentDate, 'second', 3);
    this.date = currentDate.getTime();

    return {
      date: this.date,
      bpm: Math.round(this.bpm),
      vo2: Math.round(this.vo2),
      ve: Math.round(this.ve),
    };
  }

  generateDatas(count = 3000) {
    let data = [];
    for (let i = 0; i < count; ++i) {
      data.push(this.generateData());
    }
    return data;
  }

  convertToUnixTimestamp(dateString: string): number {
    // Converti la data in un oggetto Date
    const date = new Date(dateString);

    // Verifica se la conversione è avvenuta con successo
    if (isNaN(date.getTime())) {
      throw new Error('Data non valida');
    }

    // Ottieni il timestamp UNIX (in millisecondi) dalla data
    const unixTimestamp = date.getTime();

    // Converti il timestamp in secondi dividendo per 1000
    const unixTimestampInSeconds = Math.floor(unixTimestamp / 1000);

    return unixTimestampInSeconds;
  }

  addSecondsToDate(dateString: string, secondsToAdd: number): string {
    // Converti la data in un oggetto Date
    const date = new Date(dateString);

    // Aggiungi i secondi alla data
    date.setSeconds(date.getSeconds() + secondsToAdd);

    // Ottieni la nuova data come stringa nel formato ISO 8601
    const newDate = date.toISOString();

    return newDate;
  }

  getDurationFromRange(rangeFrom: number, rangeTo: number) {
    rangeTo = new Date(rangeTo).getTime();
    rangeFrom = new Date(rangeFrom).getTime();
    return Math.floor((rangeTo - rangeFrom) / 1000);
  }

  getAllRangesValues(xAxis: am5xy.DateAxis<am5xy.AxisRendererX>): number[] {
    const ranges = xAxis.axisRanges.values.map(
      (range) => range._settings.value || 0
    );
    return ranges;
  }

  getPositionFromRange(
    chart: am5xy.XYChart,
    xAxis: am5xy.DateAxis<am5xy.AxisRendererX>,
    range: am5.DataItem<am5xy.IDateAxisDataItem>
  ) {
    return this.getPositionFromValue(chart, xAxis, range.get('value') || 0);
  }

  getPositionFromValue(
    chart: am5xy.XYChart,
    xAxis: am5xy.DateAxis<am5xy.AxisRendererX>,
    value: number
  ) {
    const pxTo = xAxis.valueToPosition(value);
    let position = chart.plotContainer.width() * pxTo;
    return Math.floor(position);
  }

  getButtonDateFrom(
    chart: am5xy.XYChart,
    xAxis: am5xy.DateAxis<am5xy.AxisRendererX>,
    button: am5.Button
  ) {
    return new Date(this.getButtonXPosition(chart, xAxis, button));
  }

  getButtonDateTo(
    chart: am5xy.XYChart,
    xAxis: am5xy.DateAxis<am5xy.AxisRendererX>,
    button: am5.Button
  ) {
    return new Date(this.getButtonXPosition(chart, xAxis, button));
  }

  getButtonXPosition(
    chart: am5xy.XYChart,
    xAxis: am5xy.DateAxis<am5xy.AxisRendererX>,
    resizeButton: am5.Button
  ) {
    var x = resizeButton.x();
    var position = xAxis.toAxisPosition(x / chart.plotContainer.width());
    return xAxis.positionToValue(position);
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        f();
      });
    }
  }

  smoothData(
    chartData: TrainingChartData[],
    n_samples = 8
  ): TrainingChartData[] {
    console.time('smoothData');
    console.log('chartData', chartData.length);
    const smoothedData: TrainingChartData[] = [];

    for (let i = 0; i < chartData.length; i += n_samples) {
      const endIndex = Math.min(i + n_samples, chartData.length);
      const samples = chartData.slice(i, endIndex);

      if (samples.length > 0) {
        const sumVO2 = samples.reduce((sum, sample) => sum + sample.vo2, 0);
        const sumBR = samples.reduce((sum, sample) => sum + sample.br, 0);
        const sumVE = samples.reduce((sum, sample) => sum + sample.ve, 0);
        const averageVO2 = sumVO2 / samples.length;
        const averageBR = sumBR / samples.length;
        const averageVE = sumVE / samples.length;

        // Creiamo un nuovo oggetto TrainingChartData con vo2 mediato
        const smoothedSample: TrainingChartData = {
          date: samples[samples.length - 1].date, // Utilizziamo la data dell'ultimo campione
          bpm: samples[samples.length - 1].bpm, // Utilizziamo il bpm dell'ultimo campione
          vo2: averageVO2,
          br: averageBR,
          ve: averageVE, //samples[samples.length - 1].ve, // Utilizziamo il ve dell'ultimo campione
        };

        smoothedData.push(smoothedSample);
      }
    }
    console.log('smoothedData', smoothedData.length);
    console.timeEnd('smoothData');

    return smoothedData;
  }
}
