import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'page-content',
  template: `<div class="page-content"><ng-content></ng-content></div>`,
  styles: [
    `
      :host {
        display: flex;
        flex-direction: column;
        flex: 1;
        --page-content-background: #fff;
        --page-content-padding: 1rem 1.5rem;
        padding: var(--page-content-padding);
        .page-content {
          width: 100%;
          padding: var(--page-content-padding);
          display: flex;
          flex-direction: column;
          gap: 1rem;
          background: var(--page-content-background);
          flex: 1;
          max-width: 1280px;
          align-self: center;
        }
      }
    `,
  ],
})
export class PageContentComponent {}
