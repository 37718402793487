export enum PlayerRole {
  Defender = 'Defender',
  Goalkeeper = 'Goalkeeper',
  FullBack = 'FullBack',
  CenterBack = 'CenterBack',
  OffensiveFielder = 'OffensiveFielder',
  Wing = 'Wing',
  Midfielder = 'Midfielder',
  Striker = 'Striker',
}

export const PlayerRoleLabel = new Map<string, string>([
  [PlayerRole.Goalkeeper, 'player.role.goalkeeper'],
  [PlayerRole.FullBack, 'player.role.fullback'],
  [PlayerRole.CenterBack, 'player.role.centerback'],
  [PlayerRole.OffensiveFielder, 'player.role.offensivefielder'],
  [PlayerRole.Wing, 'player.role.wing'],
  [PlayerRole.Midfielder, 'player.role.midfielder'],
  [PlayerRole.Striker, 'player.role.striker'],
  [PlayerRole.Defender, 'player.role.defender'],
]);
