import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'page-footer',
  template: ` <ng-content></ng-content> `,
  styles: [
    `
      :host {
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        height: 80px;
        padding: 0rem 1.5rem;
        background: var(--oxy-background-dark);
        color: #fff;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFooterComponent {}
