import { Component, HostBinding, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
})
export class PageTitleComponent {
  @Input() @HostBinding('class.dark') dark: boolean = false;
}
