import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '@oxypeak/commons';

@Directive({ standalone: true, selector: '[backButton]' })
export class BackButtonDirective {
  constructor(private navigation: NavigationService) {}

  @Input() defaultBackRoute: string[] = ['/'];

  @HostListener('click')
  onClick(): void {
    this.navigation.back(this.defaultBackRoute);
  }
}
