export function formatDuration(duration: number) {
  const hours = Math.floor(duration / 3600);
  const remainingSeconds = duration % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;
  if (hours === 0) return `${padZero(minutes)}:${padZero(seconds)}`;
  else return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)}`;
}

export function formatDurationHMS(duration: number) {
  const hours = Math.floor(duration / 3600);
  const remainingSeconds = Math.floor(duration % 3600);
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = Math.floor(remainingSeconds % 60);
  if (hours === 0) return `${minutes}m ${seconds}s`;
  return `${hours}h ${minutes}m ${seconds}s`;
}

export function formatDurationHM(duration: number) {
  const hours = Math.floor(duration / 3600);
  const remainingSeconds = duration % 3600;
  const minutes = Math.floor(remainingSeconds / 60);

  return `${hours}h ${padZero(minutes)}m`;
}

export function formatDurationSep(duration: number) {
  const hours = Math.floor(duration / 3600);
  const remainingSeconds = duration % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  const seconds = remainingSeconds % 60;
  if (hours === 0) return `${padZero(minutes)}m ${padZero(seconds)}s`;
  else return `${padZero(hours)}h ${padZero(minutes)}m ${padZero(seconds)}s`;
}

function padZero(num: number): string {
  return num < 10 ? `0${num}` : `${num}`;
}

export function getDurationPerc(timeOverThreshold: number, totalTime: number) {
  return Math.round((timeOverThreshold * 100) / totalTime);
}

export function formatTime(timestamp: number) {
  const data = new Date(timestamp);

  const hh = data.getHours();
  const mm = data.getMinutes();
  const ss = data.getSeconds();

  const hours = (hh < 10 ? '0' : '') + hh;
  const minutes = (mm < 10 ? '0' : '') + mm;
  const seconds = (ss < 10 ? '0' : '') + ss;

  return hours + ':' + minutes + ':' + seconds;
}
