import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Pagination } from '@ermitsrl/commons/table';
import { TranslateService } from '@ngx-translate/core';

import { AuthService, Language, LoggerService, OxypeakEnvironment } from '@oxypeak/commons';
import { Observable, of, ReplaySubject, Subject } from 'rxjs';

const _LANGUAGES: Language[] = [
  {
    code: 'en',
  },
  {
    code: 'it',
  },
];

const _DEFAULT_LANGUAGE: Language = {
  code: 'en',
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private static translate: TranslateService | undefined = undefined;

  languages$ = new ReplaySubject<Language[]>(1);
  languagesPagination$ = new ReplaySubject<Pagination<Language>>(1);
  currentLanguage$: Subject<Language> = new ReplaySubject<Language>(1);

  getLanguagesPagination(): Observable<Pagination<Language>> {
    return this.languagesPagination$;
  }

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient,
    private authService: AuthService,
    private logger: LoggerService,
    translate: TranslateService
  ) {
    LanguageService.translate = translate;
    this.initLanguages();
  }

  initLanguages() {
    this.fetchLanguages().subscribe((languages) => {
      this.languages$.next(languages);
    });
  }

  findLanguage(code: string): Promise<Language> {
    return new Promise((resolve, reject) => {
      this.languages$.subscribe((languages) => {
        const language = languages.find((lang) => lang.code === code);
        if (language) {
          resolve(language);
        } else {
          resolve(_DEFAULT_LANGUAGE);
        }
      });
    });
  }

  fetchLanguages(): Observable<Language[]> {
    return of(_LANGUAGES);
  }

  public static getTranslator(): TranslateService {
    if (!LanguageService.translate) {
      throw new Error('TranslateService not initialized');
    }
    return LanguageService.translate;
  }

  getLanguages() {
    return this.languages$;
  }

  async changeLanguage(newLangCode: string) {
    const currentLanguage = await this.findLanguage(newLangCode);
    LanguageService.translate?.use(currentLanguage.code);
    this.currentLanguage$.next(currentLanguage);
  }

  instant(key: string | string[], interpolateParams?: Object): string {
    return LanguageService.getTranslator().instant(key, interpolateParams);
  }

  get(key: string | string[], interpolateParams?: Object) {
    return LanguageService.getTranslator().get(key, interpolateParams);
  }

  getTranslations(code: string) {
    return LanguageService.getTranslator().getTranslation(code);
  }
}
