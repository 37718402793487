import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import { Router } from '@angular/router';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { Player } from '../models/player.model';
import { SessionPlayer } from '../models/session-player.interface';
import { SessionRange } from '../models/session-range.interface';
import { Session } from '../models/session.model';
import { Training } from '../models/training.model';
import { AuthService } from './auth.service';
import { LoggerService } from './log.service';
import { SessionService } from './session.service';

@Injectable()
export class TrainingSessionService {
  private router = inject(Router);
  private authService = inject(AuthService);

  public selectedPlayers$: BehaviorSubject<SessionPlayer[]> =
    new BehaviorSubject<SessionPlayer[]>([]);

  public session$: Subject<Session> = new Subject<Session>();
  public training$: Subject<Training> = new Subject<Training>();

  private playersData: SessionPlayer[] = [];

  training: Training = {} as Training;
  session: Session = {
    range: {
      from: 0,
      to: 0,
      duration: 0,
    },
    ranges: [],
    players: [],
    trainingId: '',
  } as Session;

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private http: HttpClient,
    private logger: LoggerService,
    private sessionService: SessionService
  ) { }

  init(trainingId: string) {
    this.playersData = [];
    this.training.id = trainingId;

    this.sessionService.fetchTraining(trainingId).subscribe({
    // this.sessionService.fetchTrainingStats(trainingId).subscribe({
      next: (training) => {
        this.training = training;

        if (!training || !training.stoppedAt) {
          this.logger.error(
            `TrainingSessionComponent - Training ${this.training.id} is not stopped`
          );
          return;
        }

        this.session = {
          training,
          trainingId: training.id,
          players: [] as SessionPlayer[],
          ranges: [],

          // TODO remove (compatibility with old sessions)
          range: {
            from: Math.floor(new Date(training.startedAt).getTime()),
            to: Math.floor(new Date(training.stoppedAt).getTime()),
            duration: Math.floor(training.duration || 0),
          }
        };

        this.training$.next(training);

        this.logger.debug('Session init', this.session);
        this.sendSession();
      },
      error: (err) => {
        this.logger.error('Error fetching training', err);
        this.router.navigate(['404']);
      },
    });
  }

  editSession(trainingId: string, sessionId: string) {
    this.playersData = [];
    this.training.id = trainingId;

    this.sessionService
      .fetchTrainingAndSession(trainingId, sessionId)
      .subscribe({
        next: ([session, training]) => {
          this.training = training;
          this.training$.next(this.training);
          this.session = session;
          this.session$.next(this.session);

          // fetch dei dati del giocatore per il grafico
          this.session.players.forEach((sessionPlayer) => {
            const player = this.training.players?.find(
              (p) => p.id === sessionPlayer.playerId
            );
            if (player) this.toggleSessionPlayer(player, false);
          });

          this.sendSelectedPlayers();

          this.sendSession();
        },
        error: (err) => {
          this.logger.error('Error fetching session&training', err);
        },
      });
  }

  downloadingPlayers = 0;
  toggleSessionPlayer(player: Player, forcedSelection?: boolean) {
    this.showLoading(true);
    const p = this.findPlayerData(player.id);

    if (!p) {
      this.downloadingPlayers++;
      // il giocatore non esiste quindi fetch dei dati e aggiunta alla lista
      this.sessionService
        .getPlayerChartData(this.training.id, player.id)
        .subscribe({
          next: (trainingChartData) => {
            if (!this.training || !this.training.stoppedAt) {
              this.logger.error(
                `SessionService - Training ${this.training.id} is not stopped`
              );
              return;
            }

            const sessionPlayer = {
              playerId: player.id,
              player,
              range: this.session.ranges[0], //TODO
              selected: true,
              chartData: trainingChartData,
            };
            this.playersData.push(sessionPlayer);
            this.downloadingPlayers--;

            if (this.downloadingPlayers === 0) this.sendSelectedPlayers();

            this.showLoading(false);
          },
          error: (err) => {
            this.logger.error(
              'Error fetching training chart data for player',
              err
            );
            this.showLoading(false);
          },
        });
      return;
    }

    p.selected = forcedSelection;
    if (this.downloadingPlayers === 0) this.sendSelectedPlayers();
    this.showLoading(false);

    return;
  }

  public setSessionRange(range: SessionRange | null) {
    if (!this.session.training || !this.session.training.stoppedAt) {
      return;
    }

    if (!range) {
      range = {
        from: new Date(this.session.training.startedAt).getTime(),
        to: new Date(this.session.training.stoppedAt).getTime(),
        duration: this.session.training.duration || 0,
      };
      this.logger.debug('SessionService.setRange (Training Range)', range);
    } else {
      this.logger.debug('SessionService.setRange (Selected)', range);
    }
    // this.session.range = range;

    // this.session.players.map((p) => (p.range = this.session.range));

    this.sendSession();
  }

  // public getSessionRange(): SessionRange | null {
  //   return this.session.range;
  // }

  /**
   * Find player data from playerData cache array
   *
   * @param playerId
   * @returns
   */
  public findPlayerData(playerId: string): SessionPlayer | undefined {
    return this.playersData.find((pd) => pd.playerId === playerId);
  }

  public setPlayerRange(playerId: string, range: SessionRange | null) {
    const p = this.findPlayerData(playerId);
    if (!p) return;

    // p.range = range || this.session.range;

    this.sendSelectedPlayers();
  }

  public sendSelectedPlayers() {
    this.session.players = [...this.playersData.filter((pd) => pd.selected)];
    this.selectedPlayers$.next(this.session.players);
    this.sendSession();
  }

  private sendSession() {
    this.session$.next(this.session);
  }

  /**
   *
   * @returns Create Session obj for API
   */
  public getSessionObject(): Session {
    const simplifiedSession: Session = { ...this.session };

    const playersFiltered = this.session.players.map((player) => {
      const simplifiedPlayer: SessionPlayer = {
        playerId: player.playerId,
        range: player.range,
        selected: player.selected,
      };
      return simplifiedPlayer;
    });

    delete simplifiedSession.training;
    simplifiedSession.players = playersFiltered as SessionPlayer[];

    // remove plotBandId from ranges
    simplifiedSession.ranges = simplifiedSession.ranges.map(range => {
      const { plotBandId, ...rangeWithoutPlotBandId } = range;
      return rangeWithoutPlotBandId;
    });

    return simplifiedSession;
  }

  isRange(range: SessionRange | null) {
    if (!range) return false;
    if (!this.training.stoppedAt) return false;

    const trainingRange = {
      from: Math.floor(new Date(this.training.startedAt).getTime()),
      to: Math.floor(new Date(this.training.stoppedAt).getTime()),
      duration: Math.floor(this.training.duration || 0),
    };

    return range.from !== trainingRange.from || range.to !== trainingRange.to;
  }

  showLoading(isLoading: boolean): void {
    this.loadingSubject.next(isLoading);
  }
}
