import { Inject, Injectable } from '@angular/core';

import { LogLevel } from '../models/log-level.model';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';

let MectronLoggerServiceInstance: LoggerService;
export const logger = () => MectronLoggerServiceInstance;

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  level: LogLevel = this.env.logger.level;
  logWithDate: boolean = true;

  constructor(@Inject('env') private env: OxypeakEnvironment) {
    MectronLoggerServiceInstance = this;
    console.log(
      '%c' + `LOGGER SERVICE with Log Level ${LogLevel[this.level]}`,
      'color: #ed581a'
    );
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  private shouldLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if (
      (level >= this.level && level !== LogLevel.Off) ||
      this.level === LogLevel.All
    ) {
      ret = true;
    }
    return ret;
  }

  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.shouldLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;

      if (this.env.logger.webApiLog) {
        // TODO send http request to send logs to server
      }

      if (this.env.logger.consoleLog) {
        this.consoleLog(level, msg, params);
      }
    }
  }

  private consoleLog(level: LogLevel, msg: any, params: any[]): void {
    switch (level) {
      case LogLevel.Debug:
        return console.debug(msg, ...params);
      case LogLevel.Info:
        return console.log('%c' + msg, 'color: #ed581a', ...params);
      case LogLevel.Warn:
        return console.warn(msg, ...params);
      case LogLevel.Error:
        return console.error(msg, ...params);
    }
  }
}

export class LogEntry {
  // Public Properties
  entryDate: Date = new Date();
  message: string = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;

  buildLogString(): string {
    let ret: string = '';

    if (this.logWithDate) {
      ret = this.getLogDate() + ' - ';
    }

    ret += 'Type: ' + LogLevel[this.level];
    ret += ' - Message: ' + this.message;
    if (this.extraInfo.length) {
      ret += ' - Extra Info: ' + this.formatParams(this.extraInfo);
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p == 'object')) {
      ret = '';

      // Build comma-delimited string
      for (let item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }

  private getLogDate(): string {
    const date = new Date();
    return (
      '[' +
      date.getUTCFullYear() +
      '/' +
      (date.getUTCMonth() + 1) +
      '/' +
      date.getUTCDate() +
      ' ' +
      date.getUTCHours() +
      ':' +
      date.getUTCMinutes() +
      ':' +
      date.getUTCSeconds() +
      '.' +
      date.getMilliseconds() +
      ']'
    );
  }
}
