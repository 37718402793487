<ng-container *ngIf="form?.get(fieldName)?.hasError('required')">
  {{ "error.required" | translate }}
</ng-container>

<ng-container *ngIf="form?.get(fieldName)?.hasError('minlength')">
  {{ "error.minChars" | translate : { min: minLength } }}
</ng-container>

<ng-container *ngIf="form?.get(fieldName)?.hasError('maxlength')">
  {{ "error.maxChars" | translate : { max: maxLength } }}
</ng-container>

<ng-container *ngIf="form?.get(fieldName)?.hasError('min')">
  {{ "error.min" | translate : { min: minValue } }}
</ng-container>

<ng-container *ngIf="form?.get(fieldName)?.hasError('max')">
  {{ "error.max" | translate : { max: maxValue } }}
</ng-container>

<ng-container *ngIf="form.get(fieldName)?.hasError('email')">
  {{ "error.email" | translate }}
</ng-container>

<ng-container *ngIf="form.get(fieldName)?.hasError('role')">
  {{ "error.userRole" | translate }}
</ng-container>

<ng-container *ngIf="form.get(fieldName)?.hasError('matching')">
  {{ "error.mustMatch" | translate }}
</ng-container>

<ng-container *ngIf="form.get(fieldName)?.hasError('pattern')">
  {{ "error.passwordPattern" | translate }}
</ng-container>
