import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  RedirectRequest
} from '@azure/msal-browser';
import { ReplaySubject, Subject } from 'rxjs';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { Permissions } from '../models/permissions.model';
import { UserRole } from '../models/user-role.enum';
import { SignalrService } from './signalr.service';

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {
  isLoggedIn$ = new ReplaySubject<boolean>(1);
  private readonly _destroying$ = new Subject<void>();
  private apiUrl = `${this.env.server.url}`;

  user$ = new ReplaySubject<{ given_name?: string; emails: string[] }>(1);
  permissions$ = new ReplaySubject<Partial<Permissions>>(1);

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    @Inject('env') private env: OxypeakEnvironment,
    private msalBroadcastService: MsalBroadcastService,
    private msal: MsalService,
    private http: HttpClient,
    private signalrService: SignalrService
  ) {
    this.msal.handleRedirectObservable().subscribe({
      next: (result: AuthenticationResult) => {
        this.updateInformations();
      },
      error: (error) => console.error(error),
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  updateInformations() {
    this.setLoginStatus();
    this.setUser();
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.msal.instance.getActiveAccount();
    if (!activeAccount && this.msal.instance.getAllAccounts().length > 0) {
      let accounts = this.msal.instance.getAllAccounts();
      this.msal.instance.setActiveAccount(accounts[0]);
    }
  }

  async login() {
    if (this.msalGuardConfig.authRequest) {
      await this.msal.instance.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      await this.msal.instance.loginRedirect();
    }
    return this.setLoginStatus();
  }

  logout() {
    sessionStorage.clear();
    this.signalrService.disconnect();
    // Use MSAL service's logout method directly
    this.msal.logoutRedirect({
      postLogoutRedirectUri: this.env.msalConfig.auth.redirectUri,
    });
  }

  setLoginStatus() {
    const isLoggedIn = this.msal.instance.getAllAccounts().length > 0;
    this.isLoggedIn$.next(isLoggedIn);
    if (isLoggedIn) {
      this.checkAndSetActiveAccount();
      this.fetchPermissionsForUserRole(this.getUserRole());
    } else {
      console.error('AuthService - User is not logged in');
    }
  }

  fetchPermissionsForUserRole(userRole: UserRole) {
    this.http
      .get<{ permission: Permissions }>(`${this.apiUrl}/role/${userRole}`)
      .subscribe({
        next: (permission) => {
          this.permissions$.next(permission.permission);
        },
        error: (error) => {
          console.error('AuthService - Error fetching permission for Role: ', userRole);
        },
      });
  }

  getClaims() {
    return this.msal.instance.getActiveAccount()?.idTokenClaims;
  }

  getUserFullname() {
    // console.log('DEBUG account', this.msal.instance.getActiveAccount());
    const fullname = this.msal.instance.getActiveAccount()?.name;
    return fullname;
  }

  getUserTeamId() {
    const userTeamId = this.msal.instance.getActiveAccount()?.idTokenClaims?.[
      'extension_TeamId'
    ] as string;
    return userTeamId;
  }

  getUserRole(): UserRole {
    // console.log('DEBUG TOKEN', this.msal.instance.getActiveAccount());
    const userRole = this.msal.instance.getActiveAccount()?.idTokenClaims?.[
      'extension_Role'
    ] as UserRole;
    return userRole;
  }

  isSuperAdmin(): boolean {
    return this.getUserRole() === UserRole.SuperAdmin;
  }

  setUser() {
    const activeAccount = this.msal.instance.getActiveAccount();

    const given_name =
      (activeAccount?.idTokenClaims?.['given_name'] as string) || 'Guest';
    const emails = activeAccount?.idTokenClaims?.['emails'] || [];

    this.user$.next({ given_name, emails });
  }
}

// export class Claim {
//   id?: number;
//   claim?: string;
//   value?: string;
//   constructor() {}
// }
