import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Device, DeviceStatus, DeviceStatusLabel } from '@oxypeak/commons';

@Component({
  standalone: true,
  selector: 'oxy-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.scss'],
  imports: [NgIf, TranslateModule],
})
export class DeviceStatusComponent {
  @Input() device!: Device;
  DeviceStatusLabel = DeviceStatusLabel;
  DeviceStatus = DeviceStatus;
}
