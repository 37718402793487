export enum UserRole {
  SuperAdmin = 'SuperAdmin',
  Trainer = 'Trainer',
  Player = 'Player',
}

export const UserRoleLabel = new Map<string, string>([
  [UserRole.SuperAdmin, 'user.role.superAdmin'],
  [UserRole.Trainer, 'user.role.trainer'],
  [UserRole.Player, 'user.role.player'],
]);

export const RoleNumber = new Map<string, number>([
  [UserRole.SuperAdmin, 0],
  [UserRole.Trainer, 1],
  [UserRole.Player, 2],
]);
