import { NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceBatteryStatus, DeviceBatteryStatusLabel } from '@oxypeak/commons';

@Component({
  standalone: true,
  selector: 'oxy-battery-status',
  templateUrl: './battery-status.component.html',
  styleUrls: ['./battery-status.component.scss'],
  imports: [NgSwitch, NgSwitchCase, TranslateModule, MatIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BatteryStatusComponent {
  @Input() status!: DeviceBatteryStatus;
  DeviceBatteryStatusLabel = DeviceBatteryStatusLabel;
  DeviceBatteryStatus = DeviceBatteryStatus;
}
