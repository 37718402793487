import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Pagination } from '@ermitsrl/commons/table';
import { of } from 'rxjs';

import { Router } from '@angular/router';
import { EMPTY, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { OxypeakEnvironment } from '../models/oxypeak-environment.model';
import { Team } from '../models/team.model';
import { AuthService } from './auth.service';
import { LoggerService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private userTeam$ = new ReplaySubject<Team>(1);
  private resolvedTeam$ = new ReplaySubject<Team>(1);
  private resolvedTeam!: Team;

  private apiUrl = `${this.env.server.url}/team`;

  constructor(
    @Inject('env') private env: OxypeakEnvironment,
    private authService: AuthService,
    private loggerService: LoggerService,
    private router: Router,
    private http: HttpClient
  ) {}

  getTeams(httpParams: HttpParams): Observable<Pagination<Team>> {
    return this.http.get<Pagination<Team>>(this.apiUrl, {
      params: httpParams,
    });
  }

  fetchUserTeam() {
    this.http
      .get<Team>(`${this.apiUrl}/mine`)
      .pipe(
        catchError((err) => this.catchError(err)),
        tap((team) => this.loggerService.log('USER TEAM Fetched', team))
      )
      .subscribe((team) => this.setUserTeam(team));
  }

  catchError(err: any) {
    if (err instanceof HttpErrorResponse) {
      if (err.status === 404) {
        console.error("UFFF, I've not a Team configured");
        return EMPTY;
      }
      if (err.status === 401 || err.status === 403) {
        console.error("UFFF, I'm unauthorized");
        this.authService.logout();
      }

      return throwError(err);
    }
    return EMPTY;
  }

  refreshUserTeam() {
    this.fetchUserTeam();
  }

  whenUserTeam(): Observable<Team> {
    return this.userTeam$.asObservable();
  }

  setUserTeam(userTeam: Team) {
    this.userTeam$.next(userTeam);
  }

  getTeam(id: string): Observable<Team> {
    return this.http.get<Team>(`${this.apiUrl}/${id}`);
  }

  createTeam(team: Partial<Team>): Observable<Team> {
    return this.http.post<Team>(this.apiUrl, team);
  }

  updateTeam(team: Partial<Team>): Observable<Team> {
    return this.http.put<Team>(`${this.apiUrl}/${team.id}`, team);
  }

  deleteTeam(team: Partial<Team>) {
    return this.http.delete(`${this.apiUrl}/${team.id}`);
  }

  whenResolvedTeam(): Observable<Team> {
    return this.resolvedTeam$.asObservable();
  }

  setResolvedTeam(resolvedTeam: Team) {
    this.resolvedTeam = resolvedTeam;
    this.resolvedTeam$.next(resolvedTeam);
  }

  getResolvedTeam(): Team {
    return this.resolvedTeam;
  }

  resolveTeam(teamId: string): Observable<Team | null> {
    // TODO cache?
    return this.http.get<Team>(`${this.apiUrl}/${teamId}`).pipe(
      catchError((err) => {
        let errorPage = '/404';

        switch (err.status) {
          case 404:
            errorPage = '/404';
            break;
          case 403:
            errorPage = '/404';
            break;
          default:
            break;
        }

        this.router.navigate([errorPage]);

        return of(null);
      })
    );
  }
}
