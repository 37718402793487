import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService, UserRole } from '@oxypeak/commons';

import { switchMap } from 'rxjs/operators';

@Injectable()
export class RedirectByRoleGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate() {
    const teamId = this.authService.getUserTeamId();
    if (!teamId) {
      this.router.navigate(['logging-in']);
    }

    return this.authService.isLoggedIn$.pipe(
      switchMap(() => {
        const userRole = this.authService.getUserRole();
        if (!userRole) {
          return this.router.navigate(['require-login']);
        }

        switch (userRole) {
          case UserRole.SuperAdmin:
            return this.router.navigate(['admin']);
          case UserRole.Trainer:
            return this.router.navigate([teamId, 'real-time']);
          case UserRole.Player:
            return this.router.navigate([teamId, 'analyze']);
          default:
            return this.router.navigate([teamId, 'real-time']);
        }
      })
    );
  }
}
